import React, {useEffect, useState} from "react";
import {epdAstralService} from "../../../../services";
import {Button, Col, Form, Icon, Input, Modal, notification, Row, Select, Steps, Upload} from "antd";
import InputField from "../../../input-field";
import FieldBlock from "../../../field-block";
import ModalCreateSignature from "../create-signature";
import CreateSignature from "../create-signature";
import SelectField from "../../../select-field";

export default function ModalJoinCompany({isShow, onCloseModal, setIsNeedUpdate}) {

  const [abonentId, setAbonentId] = useState('');
  const [companies, setCompanies] = useState([]);
  const {Option} = Select;

  const bigButtonStyle = {
    width: '200px', height: '43px', background: '#1CAA53', position: 'static', borderRadius: '10px'
  };

  useEffect(() => {
    if(isShow) {
      epdAstralService.getAvailableCompanies()
        .then((res) => {
          setCompanies(res);
        })
        .catch((err) => {
          notification.error({
            message: "Получение списка компаний",
            description: err,
          });
        });
    }
  }, [isShow]);

  const joinCompany = () => {
    if (abonentId.length > 0) {
      epdAstralService.joinCompany({abonentId: abonentId})
        .then(resp => {
          if (resp.status === 'success') {
            notification.success({
              message: "Заявка на присоединение к компании",
              description: resp.message,
            });
            onCloseModal(null);
            setIsNeedUpdate(true);
          } else if (resp.status === 'error') {
            notification.error({
              message: "Заявка на присоединение к компании",
              description: resp.message,
            });
          }
        })
        .catch((err) => {
          notification.error({
            message: "Заявка на присоединение к компании",
            description: err,
          });
        });
    }
  }

  return (<Modal
      title={<div>Авторизация компании ЭДО ЭТрН</div>}
      cancelText="Закрыть"
      className="modal-window"
      align={null}
      visible={isShow}
      onCancel={() => {
        onCloseModal(null);
      }}
      forceRender={true}
      footer={<div><Button style={{...bigButtonStyle}} onClick={() => joinCompany()} disabled={abonentId.length === 0}>
        Отправить
      </Button></div>}
    >
      <Row gutter={[16, 24]} style={{marginBottom: "34px"}}>
        <Col>
          {companies.length > 0 &&
            <Select defaultValue={null} onChange={(value) => setAbonentId(value)} style={{width: "100%"}}>
              {companies.map((item) =>
                <Option key={item.abonent_id} value={item.abonent_id} style={{width: "100%"}}>
                  {item.inn}
                </Option>
              )}
            </Select>}
        </Col>
      </Row>
    </Modal>
  )
}
